import { CheckIcon } from '@heroicons/react/20/solid'


const features = [
    {
      name: 'Real Estate',
      description: 'Capture potential homebuyers with targeted ads that showcase properties in their best light, driving more leads and closing more sales.',
    },
    {
      name: 'Health & Wellness',
      description: 'Engage health-conscious consumers with personalized campaigns that highlight the unique benefits of your wellness products or services.',
    },
    {
      name: 'Fashion Retail',
      description: 'Create trendsetting ad campaigns that turn browsers into buyers, with dynamic visuals and compelling copy that speaks to the fashion-forward.',
    },
    {
      name: 'Beauty & Personal Care',
      description: 'Attract beauty enthusiasts with stunning ads that emphasize the quality and allure of your beauty products, boosting brand loyalty and sales.',
    },
    {
      name: 'Technology & Gadgets',
      description: 'Launch innovative ad strategies that highlight the cutting-edge features of your tech products, engaging tech-savvy shoppers and driving conversions.',
    },
    {
      name: 'Home & Garden',
      description: 'Design captivating ad campaigns that inspire homeowners to beautify their spaces with your home and garden products, increasing customer engagement.',
    },
    {
      name: 'Sports & Fitness',
      description: 'Score big with fitness enthusiasts through powerful ads that motivate and inspire, connecting them with the sports gear that fuels their passion.',
    },
    {
      name: 'Toys & Games',
      description: 'Unlock playful engagement with ads that capture the fun and excitement of your toys and games, appealing to both kids and adults alike.',
    },
  ]
  

export default function IndustryHero() {
  return (
    <div className="bg-white py-24 sm:py-32 relative">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-indigo-600">Make the right choice.</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Is Advertising right for me?</p>
            <p className="mt-6 text-base leading-7 text-gray-600">
              The short answer is yes. No matter which stage of business you are in, advertising can help you grow. Whether you are just starting out or have been in business for years, advertising can help you grow your business.
            </p>
          </div>
          <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-9">
                <dt className="font-semibold text-gray-900">
                  <CheckIcon className="absolute left-0 top-1 h-5 w-5 text-indigo-500" aria-hidden="true" />
                  {feature.name}
                </dt>
                <dd className="mt-2">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}

import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid'
{/* <a href='#services' className='px-6 py-2 text-indigo-500 text-md font-semibold hover:bg-indigo-500 rounded-full bg-opacity-10 hover:bg-opacity-20 transition-all'>Services</a>
<a href='#work' className='px-6 py-2 text-indigo-500 text-md font-semibold hover:bg-indigo-500 rounded-full bg-opacity-10 hover:bg-opacity-20 transition-all'>Work with us</a>
<a href='#support' className='px-6 py-2 text-indigo-500 text-md font-semibold hover:bg-indigo-500 rounded-full bg-opacity-10 hover:bg-opacity-20 transition-all'>Support</a>
<a href='#company' className='px-6 py-2 text-indigo-500 text-md font-semibold hover:bg-indigo-500 rounded-full bg-opacity-10 hover:bg-opacity-20 transition-all'>Company</a>
<a href='#request' className=' px-6 py-2 rounded-full bg-indigo-800 text-white text-md font-semibold shadow-lg'>Request Information</a> */}
const navigation = [
  { name: 'Services', href: '#services' },
//   { name: 'Pricing', href: '#pricing' },
  { name: 'Work with Us', href: '#work' },
  { name: 'Company', href: '#company' },
  { name: 'Contact', href: '#request' },
]

export default function Masthead() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <header className="bg-white backdrop-blur-sm bg-opacity-95 sticky top-0 z-10 shadow-indigo-800 drop-shadow">
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
        <a href="#" className="-m-1.5 p-1.5">
          <span className="sr-only">Your Company</span>
          <svg className='w-auto h-6' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 184.32 31.85">
          <g id="">
          <path className="cls-2 fill-indigo-700" d="m15.18,26.42h-6.63l-.7,2.97H0L7.53,4.83h10.11l7.53,24.55h-9.15l-.83-2.97Zm-1.72-6.22l-1.85-7.21-1.59,7.21h3.44Z"/>
          <path className="cls-2 fill-indigo-700" d="m38.71,29.38h-11.58V4.83h11.58c3.55,0,6.36,1.22,8.42,3.67,2.06,2.44,3.09,5.38,3.09,8.82s-1.06,6.3-3.19,8.61c-2.13,2.31-4.9,3.46-8.32,3.46Zm-2.74-18.34v12.12h.77c1.34,0,2.4-.58,3.17-1.75.78-1.17,1.16-2.72,1.16-4.64s-.47-3.36-1.42-4.3c-.95-.95-1.92-1.42-2.92-1.42h-.77Z"/>
          <path className="cls-1 fill-gray-900" d="m72.26,11.05c-2.15.02-3.22,2.04-3.22,6.06s1.07,6.04,3.22,6.06c1.62,0,2.64-1.04,3.06-3.12l8.48,2.01c-.51,2.17-1.74,4.05-3.7,5.64-1.96,1.59-4.68,2.39-8.16,2.39s-6.39-1.15-8.71-3.46c-2.32-2.31-3.48-5.53-3.48-9.66s1.24-7.31,3.73-9.52c2.49-2.21,5.27-3.32,8.34-3.32s5.69.71,7.84,2.12c2.16,1.41,3.53,3.39,4.13,5.92l-8.48,2.01c-.47-2.08-1.49-3.12-3.06-3.12Z"/>
          <path className="cls-1 fill-gray-900" d="m86.39,4.83h8.83v9.18h5.23V4.83h8.8v24.55h-8.8v-9.18h-5.23v9.18h-8.83V4.83Z"/>
          <path className="cls-1 fill-gray-900" d="m126.28,26.42h-6.63l-.7,2.97h-7.84l7.53-24.55h10.11l7.53,24.55h-9.15l-.83-2.97Zm-1.72-6.22l-1.85-7.21-1.59,7.21h3.44Z"/>
          <path className="cls-1 fill-gray-900" d="m149.81,29.38h-11.58V4.83h11.58c3.55,0,6.36,1.22,8.42,3.67,2.06,2.44,3.09,5.38,3.09,8.82s-1.06,6.3-3.19,8.61c-2.13,2.31-4.9,3.46-8.32,3.46Zm-2.74-18.34v12.12h.77c1.34,0,2.4-.58,3.17-1.75.78-1.17,1.16-2.72,1.16-4.64s-.47-3.36-1.42-4.3c-.95-.95-1.92-1.42-2.92-1.42h-.77Z"/>
          <path className="cls-1 fill-gray-900" d="m172.71,23.61c1.32,0,1.98-.4,1.98-1.21s-.5-1.37-1.5-1.69l-3.09-1.05c-4.85-1.59-7.27-3.9-7.27-6.92s.99-4.99,2.97-6.36,4.63-2.06,7.97-2.06c4.38,0,7.66,1.85,9.85,5.55l-7.33,2.36c-.66-.94-1.51-1.4-2.57-1.4s-1.58.37-1.58,1.12c0,.66.59,1.18,1.79,1.56l3.73,1.24c4.44,1.49,6.66,3.71,6.66,6.66s-.97,5.14-2.92,6.57-4.56,2.14-7.83,2.14c-5.57,0-9.53-2.07-11.89-6.22l6.76-2.93c.72,1.76,2.15,2.65,4.27,2.65Z"/>
          <path className="cls-3 fill-white" d="m63.66,16.58c0,.59-1.04,2.49-1.33,3.17l.28.06s-.36.07-.37,0c1.58-2.44-.66-4.37-2.45-2.86.36-.98,1.32-2.02,1.05-3.13-.24-1-1.51-1.66-2.42-1.12-1.08.64-1.22,2.98-1.8,1.98.77-1.97,5.28-9.54,5.55-10.55.55-2.08-2.34-3.89-3.84-1.71q-4.42,8.22-8.83,16.44h-.1c-.37-1.27-1.05-6.02-1.42-6.74-1.08-2.11-4.3-1.25-4.05,1.43.68,2.6,1.2,5.24,1.79,7.86l-1.47-.72c-.14-.04-.17-.23-.22-.39-.12-.32-1.5-6.33-1.53-6.67-.29-3.3,3.02-5.16,5.59-3.54,1.6,1.01,1.59,3.09,2.05,4.71l7.11-13.33c2.66-3.39,7.41-.33,6.22,3.03-.39,1.11-2.88,5.11-3.61,6.66,1.86.57,2.83,2.2,2.35,4.08.57.12,1.46.7,1.46,1.33Z"/>
          <path className="cls-3 fill-white" d="m44.25,20.7l1.47.72c.11.48.65,3.26.85,3.43l11.32,5.46c1.72-1.4,5.69-6.17,6.34-7.96.47-1.29-.33-2.36-1.63-2.53l-.28-.06c.3-.68,1.33-2.58,1.33-3.17.62.38.23,2.2.32,2.29,1.43.62,2.09,2.24,1.55,3.69-.96,2.62-5.25,7.42-7.56,9.29-1.91-1.06-11.02-5.15-11.93-5.91-.55-.46-.92-1.52-1.14-2.19-.12-.36-.61-2.31-.68-2.67-.02-.12-.06-.26.03-.38Z"/>
          <path className="cls-2 fill-indigo-700" d="m62.61,19.81c1.3.17,2.1,1.24,1.63,2.53-.65,1.78-4.62,6.56-6.34,7.96l-11.32-5.46c-.2-.17-.74-2.95-.85-3.43-.59-2.62-1.11-5.26-1.79-7.86-.25-2.68,2.97-3.54,4.05-1.43.37.73,1.05,5.48,1.42,6.75h.1q4.42-8.22,8.83-16.44c1.51-2.18,4.39-.37,3.84,1.71-.27,1.02-4.78,8.58-5.55,10.55.57,1,.72-1.34,1.8-1.98.9-.54,2.17.13,2.42,1.12.27,1.1-.7,2.14-1.05,3.13,1.79-1.51,4.04.42,2.45,2.86,0,.06.35,0,.37,0Z"/>
          </g>
          </svg>
        </a>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12 items-center">
          {navigation.map((item) => (
            <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-indigo-700">
              {item.name}
            </a>
          ))}
          <a href="#request" className="text-sm font-semibold leading-6 text-white bg-indigo-600 px-6 py-2 rounded-full">
            Request Info <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <svg className='w-auto h-6' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 184.32 31.85">
          <g id="">
          <path className="cls-2 fill-indigo-700" d="m15.18,26.42h-6.63l-.7,2.97H0L7.53,4.83h10.11l7.53,24.55h-9.15l-.83-2.97Zm-1.72-6.22l-1.85-7.21-1.59,7.21h3.44Z"/>
          <path className="cls-2 fill-indigo-700" d="m38.71,29.38h-11.58V4.83h11.58c3.55,0,6.36,1.22,8.42,3.67,2.06,2.44,3.09,5.38,3.09,8.82s-1.06,6.3-3.19,8.61c-2.13,2.31-4.9,3.46-8.32,3.46Zm-2.74-18.34v12.12h.77c1.34,0,2.4-.58,3.17-1.75.78-1.17,1.16-2.72,1.16-4.64s-.47-3.36-1.42-4.3c-.95-.95-1.92-1.42-2.92-1.42h-.77Z"/>
          <path className="cls-1 fill-gray-900" d="m72.26,11.05c-2.15.02-3.22,2.04-3.22,6.06s1.07,6.04,3.22,6.06c1.62,0,2.64-1.04,3.06-3.12l8.48,2.01c-.51,2.17-1.74,4.05-3.7,5.64-1.96,1.59-4.68,2.39-8.16,2.39s-6.39-1.15-8.71-3.46c-2.32-2.31-3.48-5.53-3.48-9.66s1.24-7.31,3.73-9.52c2.49-2.21,5.27-3.32,8.34-3.32s5.69.71,7.84,2.12c2.16,1.41,3.53,3.39,4.13,5.92l-8.48,2.01c-.47-2.08-1.49-3.12-3.06-3.12Z"/>
          <path className="cls-1 fill-gray-900" d="m86.39,4.83h8.83v9.18h5.23V4.83h8.8v24.55h-8.8v-9.18h-5.23v9.18h-8.83V4.83Z"/>
          <path className="cls-1 fill-gray-900" d="m126.28,26.42h-6.63l-.7,2.97h-7.84l7.53-24.55h10.11l7.53,24.55h-9.15l-.83-2.97Zm-1.72-6.22l-1.85-7.21-1.59,7.21h3.44Z"/>
          <path className="cls-1 fill-gray-900" d="m149.81,29.38h-11.58V4.83h11.58c3.55,0,6.36,1.22,8.42,3.67,2.06,2.44,3.09,5.38,3.09,8.82s-1.06,6.3-3.19,8.61c-2.13,2.31-4.9,3.46-8.32,3.46Zm-2.74-18.34v12.12h.77c1.34,0,2.4-.58,3.17-1.75.78-1.17,1.16-2.72,1.16-4.64s-.47-3.36-1.42-4.3c-.95-.95-1.92-1.42-2.92-1.42h-.77Z"/>
          <path className="cls-1 fill-gray-900" d="m172.71,23.61c1.32,0,1.98-.4,1.98-1.21s-.5-1.37-1.5-1.69l-3.09-1.05c-4.85-1.59-7.27-3.9-7.27-6.92s.99-4.99,2.97-6.36,4.63-2.06,7.97-2.06c4.38,0,7.66,1.85,9.85,5.55l-7.33,2.36c-.66-.94-1.51-1.4-2.57-1.4s-1.58.37-1.58,1.12c0,.66.59,1.18,1.79,1.56l3.73,1.24c4.44,1.49,6.66,3.71,6.66,6.66s-.97,5.14-2.92,6.57-4.56,2.14-7.83,2.14c-5.57,0-9.53-2.07-11.89-6.22l6.76-2.93c.72,1.76,2.15,2.65,4.27,2.65Z"/>
          <path className="cls-3 fill-white" d="m63.66,16.58c0,.59-1.04,2.49-1.33,3.17l.28.06s-.36.07-.37,0c1.58-2.44-.66-4.37-2.45-2.86.36-.98,1.32-2.02,1.05-3.13-.24-1-1.51-1.66-2.42-1.12-1.08.64-1.22,2.98-1.8,1.98.77-1.97,5.28-9.54,5.55-10.55.55-2.08-2.34-3.89-3.84-1.71q-4.42,8.22-8.83,16.44h-.1c-.37-1.27-1.05-6.02-1.42-6.74-1.08-2.11-4.3-1.25-4.05,1.43.68,2.6,1.2,5.24,1.79,7.86l-1.47-.72c-.14-.04-.17-.23-.22-.39-.12-.32-1.5-6.33-1.53-6.67-.29-3.3,3.02-5.16,5.59-3.54,1.6,1.01,1.59,3.09,2.05,4.71l7.11-13.33c2.66-3.39,7.41-.33,6.22,3.03-.39,1.11-2.88,5.11-3.61,6.66,1.86.57,2.83,2.2,2.35,4.08.57.12,1.46.7,1.46,1.33Z"/>
          <path className="cls-3 fill-white" d="m44.25,20.7l1.47.72c.11.48.65,3.26.85,3.43l11.32,5.46c1.72-1.4,5.69-6.17,6.34-7.96.47-1.29-.33-2.36-1.63-2.53l-.28-.06c.3-.68,1.33-2.58,1.33-3.17.62.38.23,2.2.32,2.29,1.43.62,2.09,2.24,1.55,3.69-.96,2.62-5.25,7.42-7.56,9.29-1.91-1.06-11.02-5.15-11.93-5.91-.55-.46-.92-1.52-1.14-2.19-.12-.36-.61-2.31-.68-2.67-.02-.12-.06-.26.03-.38Z"/>
          <path className="cls-2 fill-indigo-700" d="m62.61,19.81c1.3.17,2.1,1.24,1.63,2.53-.65,1.78-4.62,6.56-6.34,7.96l-11.32-5.46c-.2-.17-.74-2.95-.85-3.43-.59-2.62-1.11-5.26-1.79-7.86-.25-2.68,2.97-3.54,4.05-1.43.37.73,1.05,5.48,1.42,6.75h.1q4.42-8.22,8.83-16.44c1.51-2.18,4.39-.37,3.84,1.71-.27,1.02-4.78,8.58-5.55,10.55.57,1,.72-1.34,1.8-1.98.9-.54,2.17.13,2.42,1.12.27,1.1-.7,2.14-1.05,3.13,1.79-1.51,4.04.42,2.45,2.86,0,.06.35,0,.37,0Z"/>
          </g>
          </svg>
            </a>
            
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              <div className="py-6">
                <a
                  href="#"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white hover:bg-gray-50"
                >
                  Request Info
                </a>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}

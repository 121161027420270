import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
    {
      question: "Why should I invest in online advertising?",
      answer:
        "Online advertising offers unprecedented targeting capabilities, allowing you to reach your ideal audience efficiently. It's cost-effective, measurable, and can significantly improve brand visibility and sales.",
    },
    {
      question: "How can adchads.com enhance my advertising strategy?",
      answer:
        "Adchads.com leverages cutting-edge technology and data analytics to optimize your ad campaigns, ensuring maximum ROI. Our platform offers personalized strategies, real-time adjustments, and detailed performance insights.",
    },
    {
      question: "Is online advertising suitable for small businesses?",
      answer:
        "Absolutely! Online advertising levels the playing field, allowing small businesses to compete with larger companies. With adchads.com, you can start with any budget, targeting your specific audience to maximize the impact.",
    },
    {
      question: "Can I track the performance of my ads on adchads.com?",
      answer:
        "Yes, adchads.com provides comprehensive analytics and reporting tools that allow you to track the performance of your ads in real-time, helping you make informed decisions to optimize your campaigns.",
    },
    {
      question: "How quickly can I see results from my advertising?",
      answer:
        "The impact of your advertising can vary, but many users start seeing increased traffic and conversions within a few days of launching their campaigns on adchads.com, thanks to our optimization algorithms.",
    },
    {
      question: "Do I need a big budget to advertise with adchads.com?",
      answer:
        "No, adchads.com is designed to accommodate all budget sizes. Our platform helps you get the most out of your advertising spend, regardless of the size of your budget.",
    },
    {
      question: "How does adchads.com target the right audience for my ads?",
      answer:
        "We use advanced targeting techniques, including demographics, interests, behaviors, and more, to ensure your ads reach the people most likely to be interested in your products or services.",
    },
    {
      question: "Can adchads.com help me with ad creative and design?",
      answer:
        "Yes, we offer creative services to help you design effective ads. Our team can assist with graphics, copywriting, and overall ad design to ensure your campaigns stand out.",
    },
    {
      question: "What types of advertising does adchads.com specialize in?",
      answer:
        "Adchads.com specializes in a variety of online advertising formats, including search engine marketing (SEM), display ads, social media advertising, video ads, and more.",
    },
    {
      question: "How does adchads.com ensure my ads are compliant with regulations?",
      answer:
        "We stay up-to-date with advertising regulations and best practices to ensure your campaigns are compliant. Our team reviews all ads for adherence to platform guidelines and legal standards.",
    },
    {
      question: "What makes adchads.com different from other advertising platforms?",
      answer:
        "Our commitment to personalization, performance, and customer service sets us apart. We treat every campaign uniquely, with a focus on delivering measurable results and providing unparalleled support to our clients.",
    },
  ];
  

export default function FAQ() {
  return (
    <div className="bg-gray-900">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
        <div className="mx-auto max-w-4xl divide-y divide-white/10">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-white">Frequently asked questions</h2>
          <dl className="mt-10 space-y-6 divide-y divide-white/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-white">
                        <span className="text-base font-semibold leading-7">{faq.question}</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon className="h-8 w-8 text-indigo-400 font-bold" aria-hidden="true" />
                          ) : (
                            <PlusSmallIcon className="h-8 w-8 text-indigo-400" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-indigo-100">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}

import {
    ArrowPathIcon,
    CloudArrowUpIcon,
    Cog6ToothIcon,
    FingerPrintIcon,
    LockClosedIcon,
    ServerIcon,
    CheckBadgeIcon,
    ChartBarIcon,
    BellAlertIcon,
    UserGroupIcon,
    SparklesIcon,
    ClipboardDocumentListIcon

} from '@heroicons/react/20/solid'

const features = [
  {
    name: 'Push to deploy.',
    description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit aute id magna.',
    icon: CloudArrowUpIcon,
  },
  {
    name: 'SSL certificates.',
    description: 'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
    icon: LockClosedIcon,
  },
  {
    name: 'Simple queues.',
    description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus.',
    icon: ArrowPathIcon,
  },
  {
    name: 'Advanced security.',
    description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit aute id magna.',
    icon: FingerPrintIcon,
  },
  {
    name: 'Powerful API.',
    description: 'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
    icon: Cog6ToothIcon,
  },
  {
    name: 'Database backups.',
    description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. ',
    icon: ServerIcon,
  },
]

const services = [
    {
        name: 'Marketing Automation',
        description: 'Expert consulting in marketing automation to streamline your efforts, with comprehensive implementation and training services.',
        icon: CheckBadgeIcon
    },
    {
        name: 'Sales Automation',
        description: 'Optimize your sales processes with our sales automation consulting, including implementation, integration, and team training.',
        icon: ChartBarIcon
    },
    {
        name: 'Customer Service Consulting',
        description: 'Enhance your customer service with our automation solutions, offering implementation, support, and training.',
        icon: UserGroupIcon
    },
    {
        name: 'E-commerce Automation',
        description: 'Boost your online sales with our e-commerce automation consulting, providing implementation, support, and training services.',
        icon: BellAlertIcon
    },
    {
        name: 'AI Consulting',
        description: 'Leverage the power of AI to streamline your business processes, with comprehensive implementation and training services.',
        icon: SparklesIcon
    },
    {
        name: 'CRM Implementation',
        description: 'Implement a CRM system that will help you manage your leads and customers.',
        icon: ClipboardDocumentListIcon
    
    }
    

]

export default function ConsultingServices() {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">Measure, Discover, Engage</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Salesforce Marketing Cloud Consulting Services.</p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
          We are a Salesforce Marketing Cloud consulting partner. We help businesses with their marketing automation needs. 
          </p>
        </div>
      </div>
      <div className="relative overflow-hidden pt-16">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <img
            src="./img/salesforce-consulting-chads.png"
            alt="App screenshot"
            className="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10"
            width={2432}
            height={1442}
          />
          <div className="relative" aria-hidden="true">
            <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]" />
          </div>
        </div>
      </div>
      <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
        <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
          {services.map((feature) => (
            <div key={feature.name} className="relative pl-9">
              <dt className="inline font-semibold text-gray-900">
                <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                {feature.name}
              </dt>{' '}
              <dd className="inline">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
}

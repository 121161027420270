import { CloudArrowUpIcon, LockClosedIcon, ServerIcon, ChartBarIcon, HandRaisedIcon, UserGroupIcon } from '@heroicons/react/20/solid'
import SwooshDivider from './SwooshDivider'

const features = [
    {
        name: 'Skyrocket Your Biz Without Burning Cash',
        description: 'AdChads motto: Pump your business without draining your wallet. We pinpoint your audience and craft irresistible messages.  ',
        icon: ChartBarIcon,
    },
    {
        name: 'Marketing That Actually Gets You High-Fives',
        description: 'Ditch the jargon for strategies that pop. AdChads delivers fresh, engaging marketing that makes your brand the talk of the town. ',
        icon: HandRaisedIcon,
    },
    {
        name: 'Your Audience, Served on a Silver Platter',
        description: 'Dreaming of the perfect audience? AdChads makes it a reality. We target with the precision of a cupid, ensuring your messages hit the bullseye every time. ',
        icon: UserGroupIcon,
    },
]


export default function Hero() {
  return (
    <div className="overflow-hidden bg-gray-900 py-24 sm:py-32 relative">
      <div className="mx-auto max-w-7xl px-6 lg:px-8 ">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-indigo-400">We're Here to help you grow!</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Marketing Made Easy. </p>
              <p className="mt-6 text-lg leading-8 text-gray-300">
                Our Philosophy is simple. Help you scale your business without throwing money away on marketing that doesn't work. 
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-300 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-white">
                      <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-500" aria-hidden="true" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
                <a href="#request" className='font-semibold text-white text-lg bg-indigo-700 hover:bg-indigo-600 px-4 py-2 rounded-lg lg:ml-8 mt-4 w-full md:w-auto inline-block'>
                    Get Started
                </a>
            </div>
          </div>
          <img
            src="./img/skeletons.jpg"
            alt="Product screenshot"
            className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-white/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
            width={2432}
            height={1442}
          />
        </div>
      </div>
      <SwooshDivider />
    </div>
  )
}

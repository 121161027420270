import React, { useEffect, useState } from 'react';
import './App.css';
import Section from './components/Section';
import Footer from './components/Footer';
import Testimonial from './components/Testimonial';
import Masthead from './components/Masthead';
import Pricing from './components/Pricing';
import Hero from './components/Hero';
import LogoCloud from './components/LogoCloud';
import IndustryHero from './components/IndustryHero';
import Consulting from './components/Consulting';
import ConsultingServices from './components/ConsultingServices';
import SwooshDivider from './components/SwooshDivider';
import LeadForm from './components/LeadForm';
import FAQ from './components/FAQ';
import Reviews from './components/Reviews';
import Benefits from './components/Benefits';
import MobileApp from './components/MobileApp';

function App() {

  // setTimeout(() => {
  //   // add one client every 4-8 seconds
  //   setClients(clients + 1);
  // }, 5000);

  const [small, setSmall] = useState(false);
  const [clients, setClients] = useState("Millions of");

  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     window.addEventListener("scroll", () =>
  //       setSmall(window.pageYOffset > 200)
  //     );
  //   }
  // }, []);

  const sections = [
    {
      id: "services",
      title: "We've got a lot to offer.",
      para: "We're here to help you achieve real growth. We provide the expertise and strategy to help you reach your goals.",
      img: "./img/hero.webp",
      subheading: "Our Services",
      herotext: "Marketing is about values. It's a complicated and noisy world, and we're not going to get a chance to get people to remember much about us. No company is. So we have to be really clear about what we want them to know about us.",
      person: "Steve Jobs",
      reverse: false,
      bullets: [
        {
          name: "Interest Based Ad Management",
          description: "We help you reach your target audience on social media by using interest based ads which are more effective than traditional ads."
        },
        {
          name: "Search Engine Optimization",
          description: "We help you rank higher in search results. We help you rank higher in search results."
        },
        {
          name: "Pay Per Click",
          description: "We're certified in Google Ads and Bing Ads. We can help you implement a successful PPC strategy for your business. "
        },
        {
          name: "Web Site Funnels Design",
          description: "We'll help you design a website that will help you convert more visitors into leads and customers."
        },
        {
          name: "CRM Implementation",
          description: "We'll help you implement a CRM system that will help you manage your leads and customers."
        }
      ]
    },
    {
      id: "work",
      title: "We're here to help you achieve real growth.",
      para: " We provide the expertise and strategy to help you reach your goals. We're here to help you achieve real growth. We provide the expertise and strategy to help you reach your goals. ",
      img: "./img/team.webp",
      subheading: "Work with Us.",
      herotext: "Marketing is no longer about the stuff that you make, but about the stories you tell.",
      person: "Seth Godin",
      reverse: true
    },
    // support
    // {
    //   id:"support",
    //   title: "Title Here",
    //   para: "Words Here",
    //   img: "URLHERE",
    //   subheading: "Subheading Here",
    //   herotext: "Hero Text Here",
    //   reverse: false      
    // },
    // company
    {
      id: "company",
      title: "About Us",
      para: "We're a small company. We like it that way. We can provide the best customer satisfaction because we're small and we care about our customers. AdChads was founded based on the principles I learned while I have been marketing products and generating sales for more than 20 years. From organic SEO, PPC, Ad Creatives, Video Production, you name it, we've done it all. ",
      img: "./img/affordable-ad-management-company.webp",
      subheading: "Just a bunch of chads on the internet. ",
      herotext: "The consumer isn't a moron; she is your wife. You insult her intelligence if you assume that a mere slogan and a few vapid adjectives will persuade her to buy anything. She wants all the information you can give her.",
      person: "David Ogilvy",
      reverse: false
    },
    // request information
    // {
    //   id:"neat",
    //   title: "Title Here",
    //   para: "Words Here",
    //   img: "URLHERE",
    //   subheading: "Subheading Here",
    //   herotext: "Hero Text Here",
    //   reverse: true      
    // }

  ]

  const stats = [
    { id: 1, name: 'Daily Ad Impressions', value: '600k+' },
    { id: 2, name: 'Sales Generated for Our Clients', value: '$20 Million+' },
    { id: 3, name: 'Leads Generated', value: clients },
  ]

  return (
    <div className="App relative">
      <Masthead />
      <Hero />

      <main className='relative'>

        <IndustryHero />
        {/* Stats */}
        <article className='relative'>
          <SwooshDivider direction={"top"} height={"h-10"} />
          <div className="bg-indigo-50 py-48 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-3">
                {stats.map((stat) => (
                  <div key={stat.id} className="mx-auto flex max-w-xs flex-col gap-y-4">
                    <dt className="text-base leading-7 text-indigo-600">{stat.name}</dt>
                    <dd className="order-first text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                      {stat.value}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <SwooshDivider height={"h-12"} />
        </article>
        {/* <div className='relative'>
        <div className="absolute bottom-0 w-full overflow-hidden leading-[0]">
          <svg className=' fill-white border-none' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
            <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className=""></path>
          </svg>
        </div>
      </div> */}
        <LogoCloud />

        {/* Sections */}
        {sections.map((section, index) => {
          return (
            <Section key={`key-` + section.id} id={section.id} reverse={section.reverse} title={section.title} para={section.para} img={section.img} subheading={section.subheading} herotext={section.herotext} bullets={section.bullets} person={section.person} />
          )
        })}
        <section>
          <Testimonial />
        </section>
        <section>
          {/* <Consulting/> */}
          <ConsultingServices />
        </section>
        {/* <section className='flex items-center justify-center py-20'>
          <MobileApp />
        </section> */}
        <section className='relative'>
          <SwooshDivider direction={'top'} height={"h-8"} color={'fill-white'} />
          <div className="bg-indigo-50 h-24"></div>
          <div className="bg-indigo-100 h-12"></div>
          <div className="bg-indigo-500 h-24"></div>
          <div className="bg-indigo-600 h-32"></div>
          <div className="bg-indigo-700 h-48"></div>
          <SwooshDivider height={"h-12"} color={'fill-white'} />
        </section>
        <Reviews />
        <section className='relative w-full'>
          <SwooshDivider direction={"top"} height={"h-10"} color={'fill-white'} />
          <FAQ />
          <SwooshDivider height={"h-12"} color={'fill-white'} />
        </section>
        {/* <section id="pricing">
          <Pricing />
        </section> */}
        <section id="benegits">
          <Benefits />
        </section>
        <section id="request" className='bg-indigo-800 h-[80rem] w-full flex items-center justify-center relative'>
          <SwooshDivider direction={"top"} height={"h-10"} color={'fill-white'} />

          {/* Contact Form */}
          <div className='rounded-lg bg-gradient-to-br from-white to-indigo-50 drop-shadow-2xl w-11/12 md:w-3/5 lg:w-2/5 px-10 py-16 max-w-xl ring-8 relative'>
            {/* <img className='h-auto w-12 absolute left-5 -z-10' src={'./img/chad-8bit.png'}/> */}
            <h1 className='mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-3xl text-center'>Get In Touch with Us!</h1>
            <LeadForm />
          </div>

        </section>
        <SwooshDivider height={"h-14"} color={`fill-gray-900`} />
      </main>

      <Footer />
      {/* <div className='w-full sticky bottom-0 bg-white py-2 items-center flex justify-center md:hidden'>
      <a href="#pricing" className="text-base font-bold text-white text-center bg-indigo-600 px-6 py-4 rounded-full w-11/12">
            Buy Now <span aria-hidden="true">&rarr;</span>
          </a>
      </div> */}
    </div>
  );
}

export default App;

import React, { useState } from 'react';
import SuccessNotification from './SuccessNotification';

// This is the payload we need to send to the server. 
// {
//     "firstName": "Chad",
//     "lastName": "Pappas",
//     "email": "chad.pappas@gmail.com",
//     "phone": "7047467542",
//     "meta": "?key=value&key2=value",
//     "org":"434794be-b9fe-40e0-ab47-ff4c4ec22640",
//     "message":"hey there smeeeellll sickkkkks"
// }

const LeadForm = ({org}) => {

    const userDetails = {
        device: {
            browser: window.navigator.userAgent,
            os: window.navigator.platform,
            deviceMemory: window.navigator.deviceMemory ? window.navigator.deviceMemory + ' GB' : 'N/A',
            hardwareConcurrency: window.navigator.hardwareConcurrency,
            language: window.navigator.language,
            languages: window.navigator.languages,
            screenSize: `${window.screen.width}x${window.screen.height}`,
            windowSize: `${window.innerWidth}x${window.innerHeight}`,
            colorDepth: window.screen.colorDepth,
            pixelDepth: window.screen.pixelDepth,
        },
        location: {
            href: window.location.href,
            hostname: window.location.hostname,
            pathname: window.location.pathname,
            search: window.location.search,
            hash: window.location.hash,
        },
        referrer: {
            referrer: document.referrer,
        },
        time: {
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            localTime: new Date().toString(),
            utcTime: new Date().toUTCString(),
        },
        campaign: {
            // Assuming you have a function to parse URL parameters
            params: parseUrlParams(window.location.search),
        }
    };


  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    // meta: userDetails,
    meta: JSON.stringify(userDetails),
    org: org || '434794be-b9fe-40e0-ab47-ff4c4ec22640',
    message: '',
    honeypot: '' // Used for spam filtering; should remain empty
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({...formData, [name]: value});
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Basic validation for required fields
    if (!formData.firstName || !formData.lastName || !formData.email || !formData.phone || !formData.message || formData.honeypot) {
      setIsValid(false);
      return; // Prevent form submission if validation fails
    }
    // if honeypot is filled in, form will not be submitted
    if (formData.honeypot.length > 0) {
      return;
    }

    // Here, you would normally send formData to your server
    console.log(formData);
    submitData(formData);
    setFormSubmitted(true); // Indicate successful submission
    // Reset form (optional)
    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      meta: '',
      org: '',
      message: '',
      honeypot: ''
    });
  };

  const renderField = (field) => {
    switch(field.type) {
      case "textarea":
        return (
          <div key={field.id}>
            <label htmlFor={field.id} className='text-md font-bold tracking-wide text-gray-800'>{field.label}</label>
            <textarea id={field.id} name={field.name} className={field.className} required onChange={handleInputChange} value={formData[field.name]}></textarea>
          </div>
        );
      case "hidden":
        return <input type="hidden" name={field.name} value={field.value} key={field.name} onChange={handleInputChange} />;
      default:
        return (
          <div key={field.id}>
            <label htmlFor={field.id} className='text-md font-bold tracking-wide text-gray-800'>{field.label}</label>
            <input type={field.type} id={field.id} name={field.name} className={field.className} required onChange={handleInputChange} value={formData[field.name]} />
          </div>
        );
    }
  };

  return (
    <form className='mt-6' onSubmit={handleSubmit}>
        {/* <pre className='bg-gray-900 rounded-lg my-10 text-blue-400 px-6 py-8 text-xs font-thin break-words h-36 overflow-x-auto'>
            {JSON.stringify(formData, null, 2)}
        </pre> */}
      <div className='grid grid-cols-1 gap-6'>
        {!isValid && <p className="text-red-500">Please fill out all required fields correctly.</p>}
        {formSubmitted && <SuccessNotification message={`Success! We'll be in touch soon! `} /> }
        {formFields.map(field => renderField(field))}
        <div>
          <button type="submit" className='w-full bg-indigo-600 hover:bg-indigo-800 focus:bg-indigo-800 transition-all text-white text-md tracking-wide uppercase font-semibold py-4 rounded-full drop-shadow-lg'>Submit</button>
        </div>
      </div>
    </form>
  );
};

const formFields = [
  {
    type: "hidden", 
    name: "honeypot", // for security purposes
    value: ""
  },
  {
    type: "hidden", 
    name: "org", // for security purposes
    value: "434794be-b9fe-40e0-ab47-ff4c4ec22640"
  },
  {
    type: "hidden", 
    name: "meta", // for security purposes
    value: "?key=value&key2=value"
  },
  {
    type: "text",
    label: "First Name",
    id: "firstName",
    name: "firstName",
    className: "w-full mt-2 border rounded-lg px-4 py-2 drop-shadow focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-transparent",
  },
  {
    type: "text",
    label: "Last Name",
    id: "lastName",
    name: "lastName",
    className: "w-full mt-2 border rounded-lg px-4 py-2 drop-shadow focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-transparent",
  },
  {
    type: "email",
    label: "Email",
    id: "email",
    name: "email",
    className: "w-full mt-2 border rounded-lg px-4 py-2 drop-shadow focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-transparent",
  },
  {
    type: "tel",
    label: "Phone",
    id: "phone",
    name: "phone",
    className: "w-full mt-2 border rounded-lg px-4 py-2 drop-shadow focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-transparent",
  },
  {
    type: "textarea",
    label: "Message",
    id: "message",
    name: "message",
    className: "w-full mt-2 border rounded-lg px-4 py-2 drop-shadow focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-transparent h-32",
  }
];

// Function to parse URL parameters
function parseUrlParams(search) {
    const params = new URLSearchParams(search);
    const obj = {};
    for (const [key, value] of params) {
        obj[key] = value;
    }
    return obj;
}

// Function to submit form data to the server
async function submitData(data) {
    // Send a POST request to the server
    const response = await fetch('https://api.adchads.com/api/leads', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
    return response.json();
}

export default LeadForm;

import { StarIcon } from '@heroicons/react/20/solid'

const reviews = [
    {
      id: 1,
      title: "Exceeded All Expectations",
      rating: 5,
      content: `
        <p>The level of detail and efficiency in campaign management by adchads.com truly exceeded all my expectations. The personalized approach and the results we've seen are nothing short of remarkable. Highly recommended!</p>
      `,
      author: 'Risako M',
      date: 'April 3, 2022',
      datetime: '2022-04-03',
    },
    {
      id: 2,
      title: "Impressive ROI",
      rating: 5,
      content: `
        <p>The team at adchads.com went above and beyond to optimize our ad campaigns. We've seen a substantial return on investment, far exceeding our initial expectations. Their expertise is unparalleled.</p>
      `,
      author: 'James L',
      date: 'June 9, 2022',
      datetime: '2022-06-09',
    },
    {
      id: 3,
      title: "Great Experience for a Small Business",
      rating: 4,
      content: `
        <p>As a small business owner, I was hesitant to spend on ads, but adchads.com made it worth every penny. The team tailored the campaign to fit my budget, and the growth we've seen is amazing.</p>
      `,
      author: 'Sophia B',
      date: 'July 15, 2022',
      datetime: '2022-07-15',
    },
    {
      id: 4,
      title: "Expert Advice, Stellar Results",
      rating: 5,
      content: `
        <p>I was blown away by the expert advice and stellar results from our campaigns. adchads.com has been an invaluable partner in our marketing strategy. Their insights and optimizations have really made a difference.</p>
      `,
      author: 'Ethan K',
      date: 'August 21, 2022',
      datetime: '2022-08-21',
    },
    {
      id: 5,
      title: "Responsive and Reliable",
      rating: 4,
      content: `
        <p>What impressed me most about adchads.com was their responsiveness and reliability. They addressed my concerns quickly and made adjustments to our campaigns that significantly improved our performance.</p>
      `,
      author: 'Olivia S',
      date: 'September 10, 2022',
      datetime: '2022-09-10',
    },
    {
      id: 6,
      title: "Outstanding Customer Service",
      rating: 5,
      content: `
        <p>The customer service at adchads.com is outstanding. They truly listen to their clients and are always looking for ways to improve the campaign and maximize results. They feel more like a partner than a service provider.</p>
      `,
      author: 'Aaron M',
      date: 'October 6, 2022',
      datetime: '2022-10-06',
    },
    {
      id: 7,
      title: "A Game-Changer for Our Business",
      rating: 5,
      content: `
        <p>Partnering with adchads.com has been a game-changer for our business. Their strategic approach to advertising and deep understanding of digital marketing have opened new doors for us. We're reaching audiences we didn't know we could.</p>
      `,
      author: 'Charlotte D',
      date: 'November 12, 2022',
      datetime: '2022-11-12',
    },
    {
      id: 8,
      title: "Efficient and Effective",
      rating: 4,
      content: `
        <p>I appreciated the efficiency and effectiveness of the adchads.com team. They streamlined our ad spend and improved our CTR significantly. While there's always room for growth, the progress we've made is commendable.</p>
      `,
      author: 'Noah J',
      date: 'December 8, 2022',
      datetime: '2022-12-08',
    },
    {
      id: 9,
      title: "Solid Strategies, Solid Results",
      rating: 4,
      content: `
        <p>The solid strategies employed by adchads.com have led to solid results for our company. Their approach to A/B testing and data-driven decisions has enhanced our marketing efforts across the board.</p>
      `,
      author: 'Emma W',
      date: 'January 19, 2023',
      datetime: '2023-01-19',
    },
    {
      id: 10,
      title: "Transformed Our Online Presence",
      rating: 5,
      content: `
        <p>Adchads.com has completely transformed our online presence. Their creative campaigns and meticulous attention to detail have not only increased our visibility but also our engagement and conversion rates. Truly transformative!</p>
      `,
      author: 'Liam T',
      date: 'February 24, 2023',
      datetime: '2023-02-24',
    },
  ]
  

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Reviews() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">We Love Happy Clients!</h2>
        <div className="mt-6 space-y-10 divide-y divide-gray-200 border-b border-t border-gray-200 pb-10">
          {reviews.map((review) => (
            <div key={review.id} className="pt-10 lg:grid lg:grid-cols-12 lg:gap-x-8">
              <div className="lg:col-span-8 lg:col-start-5 xl:col-span-9 xl:col-start-4 xl:grid xl:grid-cols-3 xl:items-start xl:gap-x-8">
                <div className="flex items-center xl:col-span-1">
                  <div className="flex items-center">
                    {[0, 1, 2, 3, 4].map((rating) => (
                      <StarIcon
                        key={rating}
                        className={classNames(
                          review.rating > rating ? 'text-yellow-400' : 'text-gray-200',
                          'h-8 w-8 flex-shrink-0'
                        )}
                        aria-hidden="true"
                      />
                    ))}
                  </div>
                  <p className="ml-3 text-sm text-gray-700">
                    {review.rating}
                    <span className="sr-only"> out of 5 stars</span>
                  </p>
                </div>

                <div className="mt-4 lg:mt-6 xl:col-span-2 xl:mt-0">
                  <h3 className="text-sm font-medium text-gray-900">{review.title}</h3>

                  <div
                    className="mt-3 space-y-6 text-sm text-gray-500"
                    dangerouslySetInnerHTML={{ __html: review.content }}
                  />
                </div>
              </div>

              <div className="mt-6 flex items-center text-sm lg:col-span-4 lg:col-start-1 lg:row-start-1 lg:mt-0 lg:flex-col lg:items-start xl:col-span-3">
                <p className="font-medium text-gray-900">{review.author}</p>
                <time
                  dateTime={review.datetime}
                  className="ml-4 border-l border-gray-200 pl-4 text-gray-500 lg:ml-0 lg:mt-2 lg:border-0 lg:pl-0"
                >
                  {review.date}
                </time>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

import { BoltIcon, GlobeAltIcon, ScaleIcon } from '@heroicons/react/24/outline'

const features = [
    {
      name: 'Creativity Meets ROI',
      description:
        `Where art meets science, AdChads thrives. Our creative campaigns don't just dazzle; they deliver. By blending innovative ideas with data-driven results, we ensure your investment not only stands out in the crowd but also pays dividends in growth and brand recognition.`,
      icon: BoltIcon,
    },
    {
      name: 'Tailored Audience Targeting',
      description:
        `Forget one-size-fits-all. AdChads crafts bespoke targeting strategies that speak directly to your audience's soul. Using cutting-edge data analysis and a deep understanding of consumer behavior, we place your brand in the spotlight for those who matter most.`,
      icon: GlobeAltIcon,
    },
    {
      name: 'Agile Marketing Mastery',
      description:
        `In today’s fast-paced world, agility is key. AdChads embodies flexibility, adapting strategies in real-time to seize opportunities or navigate challenges. Our agile approach keeps you ahead of the curve and makes every campaign as dynamic and responsive as the market demands.`,
      icon: ScaleIcon,
    },
  ]

export default function Benefits() {
  return (
    <div className="bg-white py-20 sm:py-24 lg:py-32">
      <div className="mx-auto max-w-xl px-6 lg:max-w-7xl lg:px-8">
        <h2 className="sr-only">A better way to send money.</h2>
        <dl className="grid grid-cols-1 gap-16 lg:grid lg:grid-cols-3">
          {features.map((feature) => (
            <div key={feature.name}>
              <dt>
                <div className="flex h-10 w-10 items-center justify-center rounded-xl bg-indigo-700 text-white">
                  <feature.icon className="h-6 w-6" aria-hidden="true" />
                </div>
                <p className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">{feature.name}</p>
              </dt>
              <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
}

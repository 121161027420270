// Desc: SwooshDivider component
// Props: height, direction, color, type
// Defaults of props : height = "h-24", direction = "bottom", color = "fill-white" type = "curved"
// Tailwind CSS: 
// 1. Add the following classes to the parent div:
//    - relative
// Example Usage: If no props are passed, the default values will be used.
// <SwooshDivider direction={"top"} height={"h-10"} color={'fill-indigo-500'}/>
export default function SwooshDivider({height, direction, color, type}) {
    return (
        <div className={`absolute  w-full overflow-hidden leading-[0] ${direction === 'top' ? "top-0 rotate-180" : "bottom-0"} `}>
            {type === `angled` ?
            //  Angled
            <svg className={`${!!color ? color : "fill-white"} border-none ${!!height ? height : "h-24"}  w-full`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                {direction === 'top' ? topAngledSwoosh() : bottomAngledSwoosh()}
            </svg>
             :
            //  Default 
            <svg className={`${!!color ? color : "fill-white"} border-none ${!!height ? height : "h-24"}  w-full`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                {direction === 'top' ? topSwoosh() : bottomSwoosh()}
            </svg>
             }
            
          </div>
    )
}

function topSwoosh(){
    return (
        <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" class="shape-fill"></path>
    )
}

function bottomSwoosh(){
    return (
        <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className=""></path>
    )
}

function bottomAngledSwoosh(){
    return (
        <path d="M892.25 114.72L0 0 0 120 1200 120 1200 0 892.25 114.72z" class="shape-fill"></path>
    )
}

function topAngledSwoosh(){
    return (
        <path d="M892.25 114.72L0 0 0 120 1200 120 1200 0 892.25 114.72z" class="shape-fill"></path>
    )
}